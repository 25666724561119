.swiper {
  width: 100%;
  height: 100%;
}

.swiper-button-next, .swiper-button-prev {
  color: white;
  opacity: 0.5;
}

.templeMobileSwiper {
  padding-bottom: 24px;

  & .swiper-pagination {
    bottom: 0;

    & .swiper-pagination-bullet {
      background-color: #9b51e0;
      opacity: 1;
    }

    & .swiper-pagination-bullet-active {
      background-color: white;
      border: 1.5px solid #9b51e0;
      border-radius: 50%;
      height: 8px;
      opacity: 1;
    }


  }
}

.loginMobileSwiper {
  padding-bottom: 24px;
  border-radius: 12px;

  & .swiper-pagination {
    bottom: 0;

    & .swiper-pagination-bullet {
      background-color: white;
      opacity: 1;
    }

    & .swiper-pagination-bullet-active {
      background-color: #9b51e0;
      border: 1.5px solid white;
      border-radius: 50%;
      height: 8px;
      opacity: 1;
    }


  }
}